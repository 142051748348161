<template>
  <div id="add">
    <van-form>
      <van-cell-group inset>
        <van-cell @click="showStockSelect">
          <van-field
            autocomplete="off"
            v-model="form.scName"
            required
            name="scName"
            label="仓库"
        /></van-cell>
        <!-- 需加上会员和销售员选择弹窗 -->
        <!-- <van-cell @click="showSupplierSelect"> -->
        <!-- <van-cell>
          <van-field
           autocomplete="off"
            :required="retailConfig.retailOutSheetRequireMember"
            v-model="form.retailOutSheetRequireMember"
            name="retailOutSheetRequireMember"
            label="会员"
            :readonly="curType === 'detail'"
        /></van-cell>
        <van-cell>
          <van-field
           autocomplete="off"
            v-model="form.salerId"
            name="salerId"
            label="销售员"
            :readonly="curType === 'detail'"
        /></van-cell> -->
        <van-cell @click="openDateTimePicker('form', 'paymentDate')">
          <van-field
            autocomplete="off"
            v-model="form.paymentDate"
            required
            name="paymentDate"
            label="付款日期"
        /></van-cell>
      </van-cell-group>
      <van-form style="margin: 16px">
        <div class="action">
          <Van-icon
            name="add-o"
            type="primary"
            @click="toShowProductSelect"
          ></Van-icon>
        </div>
        <CustomTable
          :scrollWidth="600"
          :showPagination="false"
          :columns="columns"
          :tableData="tableData"
        ></CustomTable>
      </van-form>

      <div style="margin: 16px">
        <van-form>
          <van-cell>合计</van-cell>
          <van-cell-group>
            <van-cell
              ><van-field
                autocomplete="off"
                label="采购数量"
                readonly
                v-model="form.totalNum"
                type="number"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="赠品数量"
                readonly
                type="number"
                v-model="form.giftNum"
            /></van-cell>
            <van-cell
              ><van-field
                autocomplete="off"
                label="含税总金额"
                readonly
                type="number"
                v-model="form.totalAmount"
            /></van-cell>
          </van-cell-group>
        </van-form>
        <!-- 约定支付 -->
        <PayType
          ref="payType"
          type="add"
          style="margin: 10px 0"
          :list="tableData2"
        ></PayType>
        <!-- 备注 -->
        <van-field
          autocomplete="off"
          v-model="form.description"
          disabled
          rows="2"
          autosize
          label="备注"
          type="textarea"
        />
        <van-cell class="actions-btn">
          <van-button type="primary" @click="onAddConfirm" round block
            >保存</van-button
          >
          <van-button
            type="info"
            @click="toDirectApprovePass"
            round
            block
            v-permission="'retail:out:approve'"
            >直接审批通过</van-button
          >
          <van-button @click="close" type="default" round block
            >关闭</van-button
          >
        </van-cell>
      </div>
    </van-form>
    <ProductSelect
      type="retail"
      columnsType="checkbox"
      :scId="this.form.scId"
      :visible="showProductSelect"
      @confirm="onSelectProductConfirm"
    ></ProductSelect>
    <DateTimePicker
      value-format="yyyy-MM-dd"
      :showPicker="showDatePicker"
      @confirm="onDatePickerConfirm"
      @cancel="showDatePicker = false"
    ></DateTimePicker>
    <StockSelect
      :visible="stockSelectVisible"
      @confirm="stockSelectConfirm"
    ></StockSelect>
    <SupplierSelect
      :visible="supplierSelectVisible"
      @confirm="supplierSelectConfirm"
    ></SupplierSelect>
  </div>
</template>

<script>
import { create, directApprovePass } from "../../../service/api/retail/out";
import SupplierSelect from "../../../components/supplierSelect..vue";
import ProductSelect from "../../../components/productSelect.vue";
import StockSelect from "../../../components/stockSelect.vue";
import CustomTable from "../../../components/customTable.vue";
import DateTimePicker from "../../../components/dateTimePicker.vue";
import PayType from "../../../components/payType.vue";
import { Notify, Toast, Dialog } from "vant";
import moment from "moment";
import { getConfiguration } from "../../../service/api/system/merchant";
import * as configApi from "../../../service/api/retail/config";

export default {
  name: "index",
  components: {
    CustomTable,
    ProductSelect,
    StockSelect,
    SupplierSelect,
    DateTimePicker,
    PayType,
  },
  data() {
    return {
      retailConfig: {},
      showDatePicker: false,
      showDatePicker: false,
      stockSelectVisible: false,
      supplierSelectVisible: false,
      showProductSelect: false,
      datePickType: "product",
      form: {},
      tableData: [],
      tableData2: [],
      columns: [
        {
          field: "productName",
          key: "productName",
          title: "商品名称",
          width: 100,
          fixed: "left",
        },
        {
          field: "productCode",
          key: "productCode",
          title: "商品编号",
          width: 80,
        },

        { field: "unit", key: "unit", title: "单位", width: 80 },
        { field: "spec", key: "spec", title: "规格", width: 80 },
        {
          field: "categoryName",
          key: "categoryName",
          title: "商品类目",
          width: 80,
        },
        { field: "brandName", key: "brandName", title: "商品品牌", width: 80 },
        {
          field: "retailPrice",
          key: "retailPrice",
          title: "参考零售价（元）",
          align: "right",
          width: 100,
        },
        {
          field: "isGift",
          key: "isGift",
          title: "是否赠品",
          width: 70,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field] ? "是" : "否";
            return <span>{text}</span>;
          },
        },

        { field: "stockNum", key: "stockNum", title: "库存数量", width: 60 },
        {
          field: "discountRate",
          key: "discountRate",
          title: "折扣（%）",
          align: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="taxPrice"
                value={text}
                type="number"
                on-input={(val) =>
                  this.changeDiscountRate(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "taxPrice",
          key: "taxPrice",
          title: "价格（元）",
          align: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="taxPrice"
                value={text}
                type="number"
                on-input={(val) =>
                  this.taxPriceInput(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },

        {
          field: "outNum",
          key: "outNum",
          title: "出库数量",
          align: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="taxPrice"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "taxAmount",
          key: "taxAmount",
          title: "含税金额",
          width: 60,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text =
              this.$utils.isFloatGeZero(row.taxPrice) &&
              this.$utils.isIntegerGeZero(row.outNum)
                ? this.$utils.mul(row.taxPrice, row.outNum)
                : "";
            return <span>{text}</span>;
          },
        },
        {
          field: "taxRate",
          key: "taxRate",
          title: "税率（%）",
          align: "right",
          width: 80,
        },
        {
          field: "description",
          key: "description",
          title: "备注",
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            const text = row[column.field];
            return flag ? (
              <span>{text}</span>
            ) : (
              <van-field
                autocomplete="off"
                name="description"
                value={text}
                type="number"
                on-input={(val) =>
                  this.inputChange(rowIndex, column.field, val)
                }
              ></van-field>
            );
          },
        },
        {
          field: "",
          key: "e",
          title: "操作",
          width: "",
          center: "left",
          fixed: "right",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const flag = this.curType === "detail";
            return flag ? (
              ""
            ) : (
              <span class="action">
                <van-icon
                  name="delete-o"
                  on-click={() => this.deleteRow(rowIndex)}
                ></van-icon>
              </span>
            );
          },
        },
      ],
    };
  },
  watch: {
    tableData: {
      handler(newVal, oldVal) {
        this.calcSum();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    configApi.get().then((res) => {
      this.retailConfig = res.data;
    });
  },
  methods: {
    close() {
      this.$route.push({ path: "/retail/out" });
    },
    changeDiscountRate(index, key, value) {
      this.inputChange(index, key, value);
      const row = this.tableData[index];
      if (
        this.$utils.isFloatGeZero(row.discountRate) &&
        this.$utils.isFloatGtZero(row.retailPrice)
      ) {
        row.taxPrice = this.$utils
          .div(this.$utils.mul(row.retailPrice, row.discountRate), 100)
          .toFixed(2);
        this.inputChange(index, "taxPrice", row.taxPrice);
      }
    },
    taxPriceInput(index, key, value) {
      this.inputChange(index, key, value);
      const row = this.tableData[index];
      console.log(row);
      if (row.retailPrice && row.retailPrice !== 0) {
        if (this.$utils.isFloatGeZero(row.taxPrice)) {
          row.discountRate = this.$utils
            .mul(this.$utils.div(row.taxPrice, row.retailPrice), 100)
            .toFixed(2);
          this.inputChange(index, "discountRate", row.discountRate);
        }
      }
    },
    toShowProductSelect() {
      if (!this.form.scId) {
        Notify({ type: "warning", message: "请先选择仓库" });
        return;
      }
      this.showProductSelect = true;
    },
    genParams() {
      const params = {
        scId: this.form.scId,
        memberId: this.form.memberId,
        salerId: this.form.salerId || "",
        paymentDate: this.form.paymentDate || "",
        description: this.form.description,
        payTypes: this.$refs.payType.getTableData().map((t) => {
          return {
            id: t.payTypeId,
            payAmount: t.payAmount,
            text: t.text,
          };
        }),
        products: this.tableData
          .filter((t) => this.$utils.isIntegerGtZero(t.outNum))
          .map((t) => {
            const product = {
              productId: t.productId,
              oriPrice: t.retailPrice,
              taxPrice: t.taxPrice,
              discountRate: t.discountRate,
              orderNum: t.outNum,
              description: t.description,
            };

            return product;
          }),
      };
      return params;
    },
    addDays(date, days) {
      if (!date || !days) {
        return "";
      }
      const result = new Date(date);
      result.setDate(result.getDate() + Number(days));
      return moment(result).format("YYYY-MM-DD");
    },
    // 校验数据
    validData() {
      if (this.$utils.isEmpty(this.form.scId)) {
        this.$utils.createError("仓库不允许为空！");
        return false;
      }

      if (
        this.retailConfig.retailOutSheetRequireMember &&
        this.$utils.isEmpty(this.form.memberId)
      ) {
        this.$utils.createError("会员不允许为空！");
        return false;
      }

      if (this.form.allowModifyPaymentDate) {
        if (this.$utils.isEmpty(this.form.paymentDate)) {
          this.$utils.createError("付款日期不允许为空！");
          return false;
        }
      }

      if (this.$utils.isEmpty(this.tableData)) {
        this.$utils.createError("请录入商品！");
        return false;
      }

      for (let i = 0; i < this.tableData.length; i++) {
        const product = this.tableData[i];

        if (this.$utils.isEmpty(product.productId)) {
          this.$utils.createError("第" + (i + 1) + "行商品不允许为空！");
          return false;
        }

        if (this.$utils.isEmpty(product.taxPrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品价格不允许为空！");
          return false;
        }

        if (!this.$utils.isFloat(product.taxPrice)) {
          this.$utils.createError("第" + (i + 1) + "行商品价格必须为数字！");
          return false;
        }

        if (product.isGift) {
          if (parseFloat(product.taxPrice) !== 0) {
            this.$utils.createError("第" + (i + 1) + "行商品价格必须等于0！");
            return false;
          }
        } else {
          if (!this.$utils.isFloatGtZero(product.taxPrice)) {
            this.$utils.createError("第" + (i + 1) + "行商品价格必须大于0！");
            return false;
          }
        }

        if (!this.$utils.isNumberPrecision(product.taxPrice, 2)) {
          this.$utils.createError(
            "第" + (i + 1) + "行商品价格最多允许2位小数！"
          );
          return false;
        }

        if (!this.$utils.isEmpty(product.outNum)) {
          if (!this.$utils.isInteger(product.outNum)) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品出库数量必须为整数！"
            );
            return false;
          }

          if (product.isFixed) {
            if (!this.$utils.isIntegerGeZero(product.outNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品出库数量不允许小于0！"
              );
              return false;
            }
          } else {
            if (!this.$utils.isIntegerGtZero(product.outNum)) {
              this.$utils.createError(
                "第" + (i + 1) + "行商品出库数量必须大于0！"
              );
              return false;
            }
          }
        } else {
          if (!product.isFixed) {
            this.$utils.createError(
              "第" + (i + 1) + "行商品出库数量不允许为空！"
            );
            return false;
          }
        }
      }

      if (!this.$refs.payType.validData()) {
        return false;
      }

      const payTypes = this.$refs.payType.getTableData();
      const totalPayAmount = payTypes.reduce(
        (tot, item) => this.$utils.add(tot, item.payAmount),
        0
      );
      if (!this.$utils.eq(this.form.totalAmount, totalPayAmount)) {
        this.$utils.createError(
          "所有支付方式的支付金额不等于含税总金额，请检查！"
        );
        return false;
      }

      return true;
    },
    // 直接审核通过订单
    async toDirectApprovePass() {
      if (!this.validData()) {
        return;
      }

      const checkStockNumArr = [];
      this.tableData
        .filter((item) => this.$utils.isIntegerGtZero(item.outNum))
        .forEach((item) => {
          if (
            checkStockNumArr.map((v) => item.productId).includes(item.productId)
          ) {
            checkStockNumArr
              .filter((v) => v.productId === item.productId)
              .forEach((v) => {
                v.outNum = this.$utils.add(v.outNum, item.outNum);
              });
          } else {
            checkStockNumArr.push({
              productId: item.productId,
              productCode: item.productCode,
              productName: item.productName,
              stockNum: item.stockNum,
              outNum: item.outNum,
            });
          }
        });

      const merchId = JSON.parse(localStorage.getItem("userInfo")).merchId;
      const res = await getConfiguration(merchId);
      if (!res.data.sellOverFlag) {
        const unValidStockNumArr = checkStockNumArr.filter(
          (item) => item.stockNum < item.outNum
        );
        if (!this.$utils.isEmpty(unValidStockNumArr)) {
          this.$utils.createError(
            "商品（" +
              unValidStockNumArr[0].productCode +
              "）" +
              unValidStockNumArr[0].productName +
              "当前库存为" +
              unValidStockNumArr[0].stockNum +
              "，总出库数量为" +
              unValidStockNumArr[0].outNum +
              "，无法完成零售出库！"
          );
          return false;
        }
      }
      const params = this.genParams();
      Dialog.confirm({
        title: "确认弹窗",
        message: "确定要直接审核通过该零售出库单吗",
      }).then(() => {
        this.loading = true;
        directApprovePass(params)
          .then((res) => {
            Toast.success(res.msg);
            this.$router.push({ path: "/retail/out" });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    onAddConfirm() {
      if (!this.validData()) {
        return;
      }
      const params = this.genParams();
      create(params).then((res) => {
        Toast.success(res.msg);
        this.$router.push({ path: "/retail/out" });
      });
    },
    // 计算汇总数据
    calcSum() {
      let totalNum = 0;
      let giftNum = 0;
      let totalAmount = 0;

      this.tableData
        .filter((t) => {
          return (
            this.$utils.isFloatGeZero(t.taxPrice) &&
            this.$utils.isIntegerGeZero(t.outNum)
          );
        })
        .forEach((t) => {
          const num = parseInt(t.outNum);
          if (t.isGift) {
            giftNum = this.$utils.add(giftNum, num);
          } else {
            totalNum = this.$utils.add(totalNum, num);
          }

          totalAmount = this.$utils.add(
            totalAmount,
            this.$utils.mul(num, t.taxPrice)
          );
        });

      this.form.totalNum = totalNum;
      this.form.giftNum = giftNum;
      this.form.totalAmount = totalAmount;
    },
    showStockSelect() {
      this.stockSelectVisible = true;
    },
    stockSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.stockSelectVisible = false;
        return;
      }
      this.stockSelectVisible = false;
      this.form.scName = row.name;
      this.form.scId = row.id;
    },
    showSupplierSelect() {
      this.supplierSelectVisible = true;
    },
    supplierSelectConfirm(row) {
      if (Object.keys(row).length === 0) {
        this.supplierSelectVisible = false;
        return;
      }
      this.supplierSelectVisible = false;
      this.form.supplierName = row.name;
      this.form.supplierId = row.id;
    },
    inputChange(rowIndex, field, val) {
      this.$set(this.tableData, rowIndex, {
        ...this.tableData[rowIndex],
        [field]: val,
      });
    },
    openDateTimePicker(type = "product", index) {
      this.datePickType = type;
      this.index = index;
      this.showDatePicker = true;
    },
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    onSelectProductConfirm(row) {
      if (this.showProductSelect) {
        this.showProductSelect = false;
      }
      if (row.length) {
        row.forEach((el) => {
          this.tableData.push({
            ...el,
            taxRate: 0,
            taxCostPrice: 0.01,
            stockNum: 1,
            isGift: false,
            productionDate: "",
            shelfLifeDays: 0,
            shelfLife: "",
          });
        });
      }
    },
    onDatePickerConfirm(val) {
      if (!val) return;
      if (this.datePickType == "product") {
        this.$set(this.tableData, this.index, {
          ...this.tableData[this.index],
          productionDate: val,
        });
      } else {
        this.form[this.index] = val;
      }
      this.showDatePicker = false;
      this.index = 0;
    },
  },
};
</script>
<style scoped>
.van-cell {
  padding: 5px 0px 5px 17px;
}
#add {
  position: relative;
}
::v-deep .van-field__body {
  height: 100%;
}
.actions-btn {
  .van-button {
    margin: 5px 5px 0 0;
  }
}
.action {
  background-color: #fff;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 26px;
  height: 36px;
}
</style>
